<template>
	<!-- <el-dialog :title="$t('c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="800px"> -->
	<el-drawer :wrapperClosable="false" :title="!!modifyForm.id?$t('6267f3aedf895209'):$t('i18nn_24c8f46012a25c89')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="500px">
	
		<div>
			<div style="">
				<el-form ref="modifyForm" :rules="modifyFormRules" :model="modifyForm" label-width="100px"
					v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
					<!-- <el-alert
					    :title="$t('daaaeb1b7b22b126')"
					    type="warning"
					    description="管理员修改,只能为数字,字母, - , _ "
					    show-icon>
					  </el-alert> -->
					<!-- <el-form-item :label="$t('i18nn_e4842b8507dd415c')" prop="">
						<el-input type="text" clearable v-model="modifyForm.nickName"></el-input>
					</el-form-item> -->
					
					
					<el-form-item :label="$t('c944a6686d996ab3')" prop="" required>
						<!-- <el-select size="mini" filterable clearable v-model="modifyForm.whNo"
							:placeholder="$t('2ad108ab2c560530')" style="width: 150px;">
							<el-option v-for="item in selectOption.wh_no" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
								:value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select> -->
						<whNoSelect ref="whNoSelect" :size="''" @changeData="changWhNo" :width="200"></whNoSelect>
					</el-form-item>
					
					<el-form-item :label="$t('i18nn_e1a3fd479469bc3b')" prop="" required>
						<!-- <el-input type="text" clearable v-model="modifyForm.zoneWhNo"></el-input> -->
						<whNoSelect ref="whNoSelect2" :size="''" @changeData="changWhNo2" :width="200"></whNoSelect>
					</el-form-item>
					<!-- <el-form-item :label="$t('hytxs0000035')" prop="" >
						<el-input type="text" clearable v-model="modifyForm.place"></el-input>
					</el-form-item> -->
					<!-- <el-form-item :label="$t('hytxs0000036')" prop="" >
						<el-select size="" filterable clearable v-model="modifyForm.placeType"
							:placeholder="$t('2ad108ab2c560530')" style="width: 150px;">
							<el-option v-for="item in selectOption.wh_place_type" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select>
					</el-form-item> -->
					
					<el-form-item :label="$t('15b3627faddccb1d')" prop="" >
						<el-input type="textarea" clearable v-model="modifyForm.remark" :maxlength="1000" show-word-limit></el-input>
					</el-form-item>
					
					<!-- <el-form-item :label="$t('i18nn_f8da18cb19a9fa8c')" prop="" >
						<el-input type="text" clearable v-model="modifyForm.skuPrefix"></el-input>
					</el-form-item> -->

				</el-form>
			</div>
		</div>

		<div class="drawer-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{$t('FormMsg.Close')}}</el-button>
			<el-button type="primary" @click="SubmitForm('modifyForm')">{{$t('204ffab8a6e01870')}}</el-button>
		</div>
	</el-drawer>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	export default {

		props: {
			openTime: {},
			row:{}
		},
		components: {
			whNoSelect
		},
		data() {
			return {
				dialogFile: false,
				loading: false,

				//修改密码
				modifyForm: {
					"zoneWhNo":"",//预报仓
					"whNo":"",//仓库
					remark: ''
				},
				modifyFormRules: {
					// skuPrefix: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
				},
				selectOption:{
					// wh_place_type:[]
				}

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
			//数据字典
			// getDicData(['wh_place_type'],
			// 	(data)=>{
			// 		this.selectOption.wh_place_type = data['wh_place_type'];
			// });
		},
		//编译挂载前
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				//初始化上传控件
				this.modifyForm = Object.assign({},this.row);
				this.$nextTick(()=>{
					try {
						this.$refs.whNoSelect.init(this.modifyForm.whNo);
						this.$refs.whNoSelect2.init(this.modifyForm.zoneWhNo);
					} catch(e) {
						//TODO handle the exception
						console.log(e);
					}
				});
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.modifyForm.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			changWhNo2(data) {
				console.log('changWhNo2', data);
				this.modifyForm.zoneWhNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
			},
			//提交
			SubmitForm(formName) {

				// let formData = Object.assign({}, this.modifyForm);
				// this.postData(this.$urlConfig.WhCustomerUpdate, formData);
				
				this.$refs[formName].validate(valid => {
					if (valid) {
						let formData = Object.assign({}, this.modifyForm);
						this.postData(this.$urlConfig.WhPlaceZoneAdd, formData);
					} else {
						console.log('error submit!!');
						this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						return false;
					}
				});
			},

			//提交信息
			// postData(url, formData) {
			// 	this.loading = true;
			// 	this.$http
			// 		.post(url, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			// 					type: 'success',
			// 				});
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '提交失败,请重试';
			// 				}
			// 				this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
			// 					type: 'warning',
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_a7d2e953195a5588'));
			// 			this.loading = false;
			// 			this.$alert('提交失败,请重试！', this.$t('cc62f4bf31d661e3'), {
			// 				type: 'warning',
			// 			});
			// 		});
			// },

			postData(url, formData) {
				this.loading = true;
				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							this.dialogFile = false;
							this.$message.success(this.$t('tips.submitSuccess'));
							this.$emit('success');
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					}).catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>